import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,

    options: {
      customProperties: true,
    },
    
    themes: {
      dark: {
        primary: "#FFF600", 
        secondary: "#424242",
        info: "#2196F3",
        success: "#4CAF50",
        error: "#FF5252",
        warning: "#FFC107",
        background: "#171A23", 
        surface: "#1F232F",
        accent: "#292E3D",
        overtext: "#FFFFFF",
        subtext: "#9b9ca2",
      },
    },
  },

  lang: {
    locales: { pt },
    current: "pt",
  },
});
