import axios from "axios";
import store from "@/store";
import router from "@/router";

export const api = axios.create({
  baseURL: "https://address.api",
  timeout: 8000,
});

// request
export async function request(method, url, data) {
  return await new Promise((resolve, reject) => {
    try {
      url = url.replaceAll("undefined", "");
      api[method](url, data).then((res) => resolve(res));
    } catch (err) {
      return reject(err);
    }
  });
}

// interceptor
api.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) => responseFailed(err)
);

const responseFailed = (err) => {
  // server error or unauthorized
  if (((err == "Error: Network Error" || err.response.status >= 500) && store.getters.token) || [401, 403].includes(err.response.status)) {
    store.dispatch("setSignOut");
    router.push({ path: "/" }).catch(() => { /* ignore */ });
  }

  return Promise.reject(err);
};
