<template>
  <div class="d-flex flex-column fill-height">
    <!-- slot view -->
    <v-main class="background">
      <v-container class="py-8">
        <div class="d-flex flex-column justify-center">
          <div class="d-flex justify-center mb-12">
            <v-responsive max-width="164">
              <v-img :src="require('@/assets/logo/logotype.png')" contain />
            </v-responsive>
          </div>

          <div class="d-flex px-12">
            <v-card
              color="primary"
              class="rounded-t-lg rounded-b-0 px-12"
              light
              flat
              link
            >
              <div class="text-h6">
                <span class="rubik"> Players </span>
              </div>
            </v-card>
          </div>

          <hr class="primary" />
        </div>
      </v-container>

      <slot />
    </v-main>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
