<template>
  <v-app id="app">
    <SplashScreen v-if="loading" />

    <Application v-else>
      <router-view />
    </Application>

    <SnackBar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import Application from "./template/Application.vue";
import SplashScreen from "./template/SplashScreen";
import SnackBar from "./components/app/SnackBar";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  components: {
    Application,
    SplashScreen,
    SnackBar,
  },

  created() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },

  beforeMount() {
    this.startApplication();
  },

  methods: {
    ...mapActions(["setSignIn", "setSignOut"]),

    // start theme
    async startApplication() {
      this.$vuetify.theme.dark = this.$store.getters.darkMode;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap");

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rubik {
  font-family: "Rubik", sans-serif;
}

.zoom:hover {
  transform: scale(1.025);
}
</style>